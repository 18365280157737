import * as React from 'react'

import Layout from "../layouts/layout"
// import sty from '../layouts/styles'
import { ContentBox } from 'src/components/Main/ContentBox'
import useImage from 'src/hooks/useImage'


const IndexPage = () => {

  const { siteRoof, productStone } = useImage()

  return (
    <Layout index seo={{ title: 'Home' }}>
      <ContentBox
        src={false}
        title='Alpisol Einblasen GmbH'
        subtitle='mit Flocken dämmen &bull; seit 1986'
        text={<>
          Besonders für Brandschutz, Schall- und Wärmeisolation ist eine gute Dämmung entscheidend.
          <br/>
          Mit der Spezialisierung auf <strong>Isolieren im Einblasverfahren</strong> bringt die Alpisol Einblasen GmbH über 30 Jahre Berufserfahrung mit sich&hellip;
        </>}
        button={{ link: '/ueber-uns' }}
      />
      <ContentBox
        src={siteRoof}
        title='Unser Service'
        subtitle='Renovation &bull; Neubau &bull; Spezial'
        text={<>
          Um Kälte und Wärme von Aussen abzuschirmen&hellip; Um den Schall aus und durch Wände zu dämpfen&hellip; Für Ihr Wohlbefinden.
          <br/>
          Es gibt viele Gründe für eine <strong>verlässliche Dämmung</strong> der Boden-, Wand- und Deckenelemente.
        </>}
        button={{ link: '/service', linkName: 'mehr erfahren' }}
      />
      <ContentBox
        inverse
        src={productStone}
        title='Produkte'
        subtitle='ökologisch &bull; konventionell &bull; sinnvoll'
        text={<>
          Auch wenn meist Flocken aus <strong>Papier</strong> und <strong>Steinwolle</strong> verarbeitet werden, steigt die Nachfrage nach <strong>Alternativen</strong> - etwa aus Kork oder hölzernen Ursprungs.
          <br/>
          Diese Materialien unterscheiden sich in einigen Ihrer Eigenschaften, welche wir Ihnen gerne etwas näher bringen.
        </>}
        button={{ link: '/produkte', linkName: 'mehr erfahren' }}
      />
    </Layout>
  )
}

export default IndexPage
